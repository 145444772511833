.product_image {
    width: 95%;
    height: 300px;
    border-radius: 25px 25px 0px 0px;
}

.product_info {
    width: 97%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 20px 20px;
    padding-bottom: 20px;
}

.product_title {
    font-size: 18px;
    line-height: 35px;
    font-weight: 700;
    text-align: center;
    width: 250px;
    padding: 10px 20px;
}


.product_info img{
    margin-right: 5px;
    margin-bottom: 15px;
}

/* .product_info del {
    font-size: 14px;
    line-height: 140.62%;
    font-weight: 400;
    color: #57585A;
    margin-right: 15px;
} */

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    max-width: 300px;
}

.image_container {
    background-color: #f1f2ed;
    text-align: center;
    border-radius: 40px 40px 0px 0px;
}



@media screen and (min-width:425px){
    
}

@media screen and (min-width:768px) {
 
}



@media screen and (min-width:1024px) {
    .product_info {
        /* width: 100%; */
        height: 220px;
        padding-bottom: 20px;
    }
}