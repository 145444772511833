.container {
    max-width: 1440px;
    margin: 0 auto;
}

.bannerBg {
    background: url('../../assests/images/partnership-mobile-banner.png');
    height: 300px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}


.orderingWrap{
    margin-top: -75px;
}


@media screen and (min-width:425px) {
    .bannerBg {
        height: 350px;
    }

    

}

@media screen and (min-width:768px) {
    .bannerBg {
        background: url('../../assests/images/partnerShip-banner.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 720px;
        margin-top: -100px;
    }

    .orderingWrap {
        margin-top: -140px;
    }
}


@media screen and (min-width:1024px) {
    .bannerBg {
        height: 940px;
    }

     .orderingWrap {
         margin-top: -170px;
     }
}

