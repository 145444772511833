.container {
    padding: 20px 0 20px;
}

.imgWrap {
    width: 100%;
    text-align: center;
}

.imgWrap img {
    width: 70%;
}

.contentWrap {
    text-align: center;
    padding: 20px 0 20px;
}


.cardHeader {
    font-size: 18px;
    font-weight: 700;
    color: #000000;
}

.cardSubHeader {
    font-size: 14px;
    padding-top: 8px;
}

.starWrap {
    padding: 15px 0 15px;
}

.starWrap img {
    width: 25px;
    margin-right: 10px;
}

.sizeWrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 20px;
}


.sizeBorderWrap {
    border-left: 1px solid black;
    border-right: 1px solid black;
    padding: 0px 5px 0px 5px;
    margin-bottom: 20px;
}

.sizeBorderWrap:nth-last-child(1) {
    border-right: none;
    border-left: none;
    padding: 0px 0px 0px 5px;
}


.sizeBorderWrap:nth-last-child(4) {
    border-left: none;
    padding: 0px 5px 0px 0px;
    border-right: none;
}

.sizeBorderWrap:nth-last-child(2) {
    padding: 0px 5px 0px 5px;
    border-left: none;
}

.sizeContent {
    font-size: 14px;
}

.sizeContent span {
    padding-left: 5px;
}


.priceContent {
    text-align: start;
    font-size: 20px;
    padding-top: 5px;
    font-weight: bold;
}

.contentId {
    padding-bottom: 15px;
    font-size: 24px;
    font-weight: 400;
}

.price {
    font-size: 24px;
    font-weight: 400;
}

.contentDescWrap {
    width: 90%;
    margin: auto;
    padding: 0px 0px 18px;
}

.contentDesc {
    font-size: 12px;
    line-height: 20px;
}


.qtyWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
}

.qty {
    font-size: 24px;
    font-weight: 700;
}

.qtyNumWrap {
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #949494;
    border-radius: 30px;
    margin-right: 10px;
    margin-left: 10px;
}

.qtyNumWrap p {
    margin: 0 10px;
    font-size: 18px;
}

.qtyNumWrap button {
    border: none;
    background: none;
    font-size: 18px;
}

@media screen and (min-width:425px) {

    .imgWrap img {
        width: 70%;
        max-width: 350px;
    }

    .contentDescWrap {
        width: 80%;
        margin: auto;
    }
.sizeContent {
    font-size: 16px;
}

.priceContent {
    font-size: 24px;
    padding-top: 10px;
}

.sizeBorderWrap {
    padding: 0px 10px 0px 10px;
    margin-bottom: 20px;
}

.sizeBorderWrap:nth-last-child(1) {
    border-right: none;
    border-left: none;
    padding: 0px 0px 0px 10px;
}


.sizeBorderWrap:nth-last-child(4) {
    border-left: none;
    padding: 0px 10px 0px 0px;
    border-right: none;
}

.sizeBorderWrap:nth-last-child(2) {
    padding: 0px 10px 0px 10px;
    border-left: none;
}
}

@media screen and (min-width:768px) {
    .container {
        padding: 0px 0 25px;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .imgWrap {
        width: 100%;
        text-align: center;
    }

    .imgWrap img {
        max-width: initial;
        /* max-width: 350px; */
        width: 90%;
        height: 380px;
        border-radius: 50px;
    }

    .cardSubHeader {
        font-size: 16px;
        padding-top: 8px;
    }

    .contentWrap {
        text-align: start;
        padding: 0px 0 0px;
    }

    .contentDescWrap {
        /* width: 100%; */
        width: 300px;
        margin: 0;
        padding: 0px 0px 18px;
    }


    .qtyBtnWrap {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .qtyWrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 0px;
    }

    .qty {
        font-size: 24px;
        font-weight: 700;
        margin-right: 0px;
    }

    .qtyNumWrap {
        margin-left: 10px;
        margin-right: 10px;
    }

    .sizeWrap {
        justify-content: flex-start;
        padding: 0 0px;
    }

}

@media screen and (min-width:900px) {


    .imgWrap {
        width: 50%;
        text-align: center;
    }

    .imgWrap img {
        /* max-width: initial; */
        max-width: 400px;
        width: 90%;
        height: 480px;
        border-radius: 50px;
    }

    .cardHeader {
        font-size: 30px;
        }

        .cardSubHeader {
            font-size: 20px;
            padding-top: 8px;
        }

    .starWrap img {
        width: 30px;
    }

    .price {
        font-size: 36px;
    }

    .contentDesc {
        font-size: 18px;
        line-height: 35px;
    }


    .contentDescWrap {
        /* width: 100%; */
        width: 400px;
        margin: 0;
        padding: 0px 0px 18px;
    }


    .qtyNumWrap {
        padding: 8px 18px;
        margin-right: 15px;
        margin-left: 15px;
    }

    .qtyNumWrap p {
        margin: 0 12px;
        font-size: 26px;
    }

    .qtyNumWrap button {
        font-size: 26px;
    }

.sizeContent {
    font-size: 20px;
}

.priceContent {
    font-size: 28px;
}
}


@media screen and (min-width:1200px) {

    .container {
        padding: 0px 0 30px 50px;
    }

    .imgWrap {
        width: 40%;
    }

    .imgWrap img {
        /* max-width: initial; */
        max-width: 450px;
        width: 100%;
        height: 480px;
        border-radius: 60px;
    }
    .cardHeader {
        font-size: 36px;
    }

    .cardSubHeader {
        font-size: 24px;
        padding-top: 8px;
    }

    .contentWrap {
        padding: 0px 0 0px 30px;
    }

    .contentDescWrap {
        /* width: 100%; */
        width: 500px;
        margin: 0;
        padding: 0px 0px 18px;
    }

    .qtyBtnWrap {
        justify-content: space-between;
    }

    .qtyNumWrap {
        margin-right: 0px;
        margin-left: 20px;
        }
.sizeContent {
    font-size: 24px;
}

.priceContent {
    font-size: 36px;
}

        }

@media screen and (min-width:1350px) {
    .container {
        padding: 0px 0 30px 80px;
    }

    .contentWrap {
        padding: 0px 0 0px 50px;
    }
.sizeWrap {
    justify-content: flex-start;
    padding: 0 0px;
    width: 520px;
}
}