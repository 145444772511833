.card {
    text-align: center;
    margin-bottom: 25px;

}

.imgWrap {
    width: 100%;
}

.imgWrap img {
    height: 50px;
    width: 40px;
}

.title {
    font-size: 18px;
    color: #e56d27;
    font-weight: 700;
    padding: 0px 0 10px;
}

.desc {
    font-size: 14px;
    line-height: 25px;
}


@media screen and (min-width:768px){

    .card {
        text-align: start;
        margin-bottom: 30px;
        width: 50%;
        padding-left: 30px  ;
    }


    .imgWrap img {
        height: 80px;
        width: 70px;
    }

    .title {
    padding: 10px 0 20px;
    font-size: 26px;
    }

    .desc {
        font-size: 20px;
        line-height: 30px;
    }
}


@media screen and (min-width:1024px){
    .card {
        text-align: start;
        margin-bottom: 50px;
        padding-left: 40px;
        width: 50%;
    }


    .imgWrap img {
        height: 86px;
        width: 100px;
    }

    .title {
        font-size: 36px;
    }

    .desc {
        font-size: 24px;
        line-height: 40px;
    }

}


@media screen and (min-width:1300px){
    .card {
        padding-left: 50px;
    }
}


