.nav {
 display:  flex;
 align-items: center;
 justify-content: center;
 width: 95%;
 margin: 0 auto;
}

.pages {
 display: flex;
 list-style: none;
 width: 200px;
 justify-content: space-evenly;
}

.pages li {
 font-size: 24px;
line-height: 33px;
cursor: pointer;
}

.unSelected {
 text-decoration: none;
color: black;

}

.selected {
 text-decoration: none;
 color: #E56D27;
}

.arrow {
 width: 34px;
}

.prev, .next {
 cursor: pointer;
 width: 34px;
}