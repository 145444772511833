.pageCountWrap {
    padding-left: 20px;
    margin-bottom: 20px;
}

.pageCountWrap p {
    font-size: 14px;

}

@media screen and (min-width:425px) {
    .pageCountWrap {
        padding-left: 30px;
    }
}

@media screen and (min-width:768px) {
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .pageCountWrap {
        padding-left: 40px;
        margin-bottom: 0px;
    }

    .pageCountWrap p {
        font-size: 18px;
    }
}

@media screen and (min-width:1024px) {
    .pageCountWrap {
        padding-left: 80px;
        margin-bottom: 0px;
    }
}