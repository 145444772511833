.container {
    padding: 15px 20px 15px;
}

.contactHead {
    font-size: 14px;
    font-weight: 700;
}

.radioHead {
    font-size: 14px;
    font-weight: 400;
    padding-top: 10px;
}

.radioContentWrap {
    display: flex;
    align-items: center;
}

.radioWrap {
    display: flex;
    align-items: center;
    padding-right: 7px;
}

.radioWrap input {
    appearance: none;
    background-color: #ffffff;
    border: 1.7px solid #000000;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
}

.radioWrap input:checked:after {
    content: ' ';
    width: 10.5px;
    height: 11px;
    border-radius: 50px;
    position: absolute;
    top: 2.9px;
    background: #e56d27;
    box-shadow: inset 0px 0px 10px #e56d27;
    text-shadow: 0px;
    left: 2.9px;
    font-size: 32px;
}

.radioWrap input:checked {
    background-color: #ffffff;
    color: #ffffff;
    border: 1.7px solid #e56d27;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1), inset 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.radioWrap input:active,
.radioWrap input:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}


.radioWrap label {
    font-size: 12.7px;
    font-weight: 400;
    margin-left: 2px;
}

.formWrap {
    padding-top: 15px;
}

.inputWrap {
    display: flex;
    flex-direction: column;
    padding-top: 14px;
}

.inputWrap p {
    color: red;
    padding: 6px;
    font-size: 12px;
    /* border: 1px solid black; */
}

.inputWrap div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  /* border: 1px solid red; */
}

.inputWrap textarea {
    resize: none;
}

.inputWrap label {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 2px;
}

.inputWrap span {
    color: #e56d27;
}

.inputWrap textarea {
    width: 100%;
    border-radius: 7.76583px;
    padding: 6px 10px;
    outline: none;
    border: 0.388292px solid #000000;
}


.inputWrap input {
    width: 100%;
    padding: 6px 10px;
    border-radius: 7.76583px;
    outline: none;
    border: 0.388292px solid #000000;
}


.checkboxWrap {
    display: flex;
    align-items: center;
    padding: 15px 0 18px;
}

.checkboxWrap input {
    appearance: none;
    background-color: #ffffff;
    border: 1px solid #000000;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
}


.checkboxWrap input:checked:active,
.checkboxWrap input:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}


.checkboxWrap input:checked {
    background: #e56d27;
    border: 1px solid #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
        inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

.checkboxWrap input:checked:after {
    content: '\2714';
    font-size: 12px;
    position: absolute;
    top: -1px;
    left: 3px;
    color: #ffffff;
}


.checkboxWrap span {
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
}


@media screen and (min-width:375px) {
    .radioWrap label {
        font-size: 14px;
        margin-left: 6px;
    }

    .radioWrap {
        display: flex;
        align-items: center;
        padding-right: 12px;
    }
}

@media screen and (min-width:425px) {

    .radioWrap {
        padding-right: 0px;
        width: 42%;
    }
}


@media screen and (min-width:768px) {


    .container {
        padding: 20px 0px 40px 40px;
    }

    .contactHead {
        font-size: 36px;
    }

    .radioHead {
        font-size: 24px;
        padding-top: 15px;
    }

    .formWrap {
        padding-top: 18px;
    }


    .radioWrap {
        padding-right: 0px;
        width: 40%;
    }

    .radioWrap input {
        padding: 9px;
    }

    .radioWrap input:checked:after {
        width: 12px;
        height: 12px;
        top: 3px;
        left: 3px;
    }

    .radioWrap label {
        font-size: 24px;
        margin-left: 8px;
    }

    .inputWrap {
        padding-top: 18px;
        width: 80%;
    }

    .inputWrap p {
        font-size: 16px;
        /* border: 1px solid black; */
    }
    

    .checkboxWrap{
        width: 80%;
    }

    .inputWrap label {
        font-size: 24px;
        padding-bottom: 4px;
    }

    .inputWrap textarea {
        border-radius: 20px;
        font-size: 20px;
        padding: 12px 16px;
        border: 1px solid #000000;
    }


    .inputWrap input {
        padding: 12px 16px;
        font-size: 20px;
        border-radius: 20px;
        border: 1px solid #000000;
    }

    .checkboxWrap span {
        font-size: 24px;
        margin-left: 15px;
    }

    .checkboxWrap input {
        padding: 11px;
        border-radius: 3px;
    }

    .checkboxWrap input:checked:after {
        font-size: 16px;
        top: -1px;
        left: 4px;
    }

    .checkboxWrap {
        padding: 18px 0 22px;
    }
}

@media screen and (min-width:1024px) {
    .container {
        padding: 25px 0px 45px 80px;
    }

    .inputWrap {
        padding-top: 18px;
        width: 75%
    }


    .checkboxWrap {
        width: 75%;
    }

    .radioWrap {
        padding-right: 0px;
        width: 28%;
    }

}