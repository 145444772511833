.navbar_items {
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  position: relative;
  box-shadow: 0px 4px 12px rgba(229, 109, 39, 0.25);
}

.nav {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 80px);
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
  background-color: white;
  list-style: none;
}

.Logo {
  width: 121.45px;
  height: 40px;
  cursor: pointer;
}

.nav_active {
  left: 0;
  opacity: 1;
  transition: all 0.5 ease;
  z-index: 1;
  background-image: url('../../assests/images/Yellow_Amoeba.png'), url('../../assests/images/greyBg-right.png');
  background-repeat: no-repeat, no-repeat;
  background-size: 95%, 95%;
  background-position: left 70%, right 30% ;
}

.menu_item_selected, .menu_item {
  width: 100%;
  text-align: center;
  padding: 15px;

}

.call_container {
  position: relative;
  background-color: white;
  height: 50px;
  width: 50px;
  border: 5px solid #E56D27;
  border-radius: 0px 25px 25px 25px;
  margin-right: 30px;
}

.black, .branded {
  position: absolute;
  width: 18px;
  height: 18px;
}

.black {
  bottom: 8px;
  left: 4px;
}

.branded {
  bottom: 14px;
  left: 14px;
}

.btn {
  background: #E56D27;
border-radius: 0px 25px 25px 25px;
width: 80%;
max-width: 250px;
height: 50px;
margin: 20px auto;
display:flex;
align-items: center;
color: white;
font-size: 14px;
}

.btn {
  text-align: right;
}


@media screen and (min-width: 768px) { 
  .btn {
    display: none;
  }
  .Logo {
    width: 180px;
   height: 60px;
   margin-right: 20px;
   }

  .navbar_items {
    box-shadow: none;
    font-size: 14px;
  }

  .icon {
    display: none;
  }

  .nav {
    position: relative;
    left: 0;
    top: 0;
    height: 80px;
    justify-content: space-evenly;
    flex-direction: row;
    background: transparent;
    align-items: center;
  }

  .menu_item:hover, .menu_item_selected  {
    color: #E56D27;
    border-bottom: 4px solid #E56D27;
    width: fit-content;
   }

   .menu_item {
    cursor: pointer;
     border-bottom: 4px solid transparent;
     width: fit-content;
   }
  
}

@media screen and (min-width: 1024px)  {
  .Logo {
    width: 227.73px;
   height: 70px;
   }

   .nav {
     width: 70%;
   }

   .navbar_items {
    font-size: 18px;
  }
}
