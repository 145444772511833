.header {
 position: sticky;
 left: 0;
 right: 0;
 top: 0;
 z-index: 9999;
}

/* .container {
 max-width: 1440px;
 margin: 0 auto;
 background-color: white;
}

.layout {
 background: #E56D27;
} */