.container {
    position: relative;
    /* height: 500px; */
    /* overflow: hidden; */
}

.contentWrap {
    padding: 35px 0 0 20px;
}

.heroContent {
    color: #57585a;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
    line-height: 1.6;
}

.heroContent span {
    color: #e56d27;
}

.boneImg {
    width: 100%;
    max-width: 120px;
    position: relative;
}

.boneImg img {
    width: 100%;
}

.boneContent {
    position: absolute;
    top: 22px;
    right: 22px;
    transform: rotate(-11deg);
}

.boneContent span {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
}

.imgVectorMobileWrap {
    position: absolute;
    top: 0;
    width: 52%;
    right: 0;
    z-index: -10;
}

.imgVectorMobileWrap img {
    width: 100%;
    object-fit: contain;

}

.imgVectorDesktopWrap {
    display: none;
}

.imgDogWrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -60px;
}

.imgDogWrap img {
    width: 90%;
    /* object-fit: contain; */
}

@media screen and (min-width:375px) {
    .contentWrap {
        padding: 38px 0 0 28px;
    }

    .heroContent {
        font-size: 24px;
        padding-bottom: 22px;
    }

}


@media screen and (min-width:425px) {


    .contentWrap {
        padding: 42px 0 0 38px;
    }


    .imgDogWrap {
        margin-top: -80px;
    }
}


@media screen and (min-width:580px) {


    .heroContent {
        font-size: 34px;
        padding-bottom: 15px;
    }

    .contentWrap {
        padding: 70px 0 0 55px;
    }

    .boneImg {
        max-width: 130px;
    }

    .boneContent {
        top: 21px;
        right: 18px;
        transform: rotate(-11deg);
    }

    .boneContent span {
        font-size: 20px;
    }

    .imgVectorMobileWrap {
    width: 40%;
    }

    .imgDogWrap {
        margin-top: -130px;
    }

    .imgDogWrap img {
        width: 500px;
        /* object-fit: contain; */
    }
}


@media screen and (min-width:768px) {



    .imgVectorMobileWrap {
        display: none;
    }

    .heroContent {
        font-size: 30px;
        padding-bottom: 15px;
    }

    .contentWrap {
        padding: 30px 0 30px 35px;
    }

    .imgVectorDesktopWrap {
        display: block;
        position: absolute;
        top: -100px;
        width: 300px;
        right: 0;
        z-index: -1;
    }

    .imgVectorDesktopWrap img {
        width: 100%;
        object-fit: contain;
        float: right;
    }

    .imgDogWrap {
        width: 45%;
        display: initial ;
        margin-top: 0px;
        position: absolute;
        top: -102px;
        right: 0;
        z-index: -1;
    }

    .imgDogWrap img {
        width: 100%;
        /* object-fit: contain; */
    }
}


@media screen and (min-width:920px){
    .heroContent {
        font-size: 38px;
        padding-bottom: 15px;
    }

    .imgVectorDesktopWrap {
        width: 400px;
    }
}


@media screen and (min-width:1150px) {
    .heroContent {
        font-size: 48px;
        padding-bottom: 15px;
    }

    .imgVectorDesktopWrap {
        width: 450px;
    }

    .boneImg {
        max-width: 140px;
    }

    .boneContent {
        top: 23px;
        right: 16px;
        transform: rotate(-10deg);
    }

    .boneContent span {
        font-size: 22px;
    }

    

}


@media screen and (min-width:1300px){

    .contentWrap {
        padding: 50px 0 50px 85px;
    }


    .imgVectorDesktopWrap {
        width: 600px;
    }

    .imgDogWrap {
        width: 700px ;
    }
}