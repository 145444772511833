/* .container {
    max-width: 1440px;
    margin: 0 auto;
}

*/ .bannerBg {
    background: url('../../assests/images/insights_mobile.png');
    height: 300px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}


.orderingWrap {
    margin-top: -75px;
}


.cards_container {
    padding: 25px 20px 15px;
    position: relative;
}


.categoriesWrap {
    padding: 0 20px 20px;
}


.category_section {
    padding-top: 20px;
}

.heading {
 font-size: 18px;
line-height: 25px;
color: #E56D27;
padding-bottom: 10px;
}

.underline {
 width: 50px;
height: 4px;
background: #E56D27;
}

.list_item {
 width: 290px;
 border-bottom:  1px solid #D8D8D8 ;
 padding-bottom: 5px;
 text-transform: capitalize;
 cursor: pointer;
 margin-top: 10px;
 list-style: none;
 font-size: 14px;
 }

 .yellow_bg_mobile {
  background: url('../../assests/images/yellow_right.png') no-repeat bottom/contain;
  height: 220px;
  position: absolute;
  width: 280px;
  bottom: 0;
  right: 0;
  z-index: -1;
 }

 .video_section {
  padding: 0px 20px;
  font-weight: bold;
  font-size: 16.6617px;
  line-height: 14px;
 }

 .video_section p {
  margin: 20px 0px 20px 0px;
 }

 .video_section span {
  color: #E56D27;;
 }

 .video {
  text-align: 'center';
  padding-bottom: 30px;
  width: 100%;
  margin: 0 auto;
 }

 .video img {
  width: 100%;
 }

 .active {
  background: #E56D27;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  justify-content: flex-start;
  display: flex;
  cursor: pointer;
 }
 
 .inactive {
  background: #000000;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  justify-content: flex-start;
  cursor: pointer;
 }

 .testimonial_section {
  padding:0px 20px;
 }

 .testimony_header {
  font-weight: bold;
font-size: 18px;
line-height: 15px;
color: #E56D27;
margin: 20px auto;

 }

 @media screen and (min-width:425px) {
     .bannerBg {
         height: 350px;
     }

     .cards_container {
         padding: 30px 30px 20px;
     }

     .categoriesWrap {
         padding: 0 30px 25px;
     }
 }

 @media screen and (min-width:768px) {

  .yellow_bg_mobile {
   display: none;
  }
     .bannerBg {
         background: url('../../assests/images/insights_desk.png');
         background-repeat: no-repeat;
         background-size: 100% 100%;
         height: 720px;
         margin-top: -100px;
     }

     .orderingWrap {
         margin-top: -140px;
     }

     .searchWrap {
         display: flex;
         justify-content: flex-end;
         padding: 20px 0;
     }

     .heading{
         padding-bottom: 15px;
         padding-top: 15px;
     }

     .eventCategoryWrap {
         width: 100%;
         display: flex;
         justify-content: space-between;
         padding: 35px 0;
     }

     .yellow_bg {
      background: url('../../assests/images/yellow_right.png') no-repeat bottom/cover;
      height: 400px;
      position: absolute;
      width: 520px;
      bottom: 0;
      right: 0;
      z-index: -1;
     }

     .cards_container {
         padding: 0px 0px 0px 40px;
         width: 70%;
     }

     .categoriesWrap {
         padding: 0 40px 0px 0px;
         width: 30%;
     }

     .category_section {
         padding-top: 0px;
         padding-bottom: 35px;
     }

     .list_item {
         width: initial;
         padding-bottom: 10px;
         font-size: 16px;
         margin-top: 15px;
     }

     .testimony_header {
      font-size: 38px;
      margin-bottom: 20px;
     }

     .event_category_Section {
      position: relative;
     }

     .video_section {
font-size: 38px;
padding: 0 40px 0px 40px;
      
     }

     .testimonial_section {
      padding: 0 40px 0px 40px;
     }

     .video {
      width: 600px;
 
     }

     .video_section p {
      margin: 20px 0px 40px 0px;
     }

    

 }



 @media screen and (min-width:1024px) {
     .bannerBg {
         height: 940px;
     }

     .orderingWrap {
         margin-top: -170px;
     }

     .searchWrap {
         padding: 25px 0;
     }

     .cards_container {
         padding: 0px 0px 0px 80px;
         width: 70%;
     }

     .categoriesWrap {
         padding: 0 80px 0px 0px;
         width: 30%;
     }

     .list_item {
         width: initial;
         padding-bottom: 10px;
         font-size: 18px;
         margin-top: 15px;
     }

     .testimony_header {
      font-size: 48px;
line-height: 40px;
     }

     .yellow_bg {
      height: 620px;
      width: 870px;
     }

     .video_section {
      font-weight: bold;
      font-size: 48px;
line-height: 40px;
padding: 0 100px;
margin-bottom: 20px;
      
     }
      
           .testimonial_section {
            padding: 0 100px;
            margin-bottom: 20px;
           }

     
     .video {
      width: 900px;
     }
    
}


@media screen and (min-width:1300px){

    .searchWrap {
        padding: 25px 20px 25px 0;
    }

    .cards_container {
        padding: 0px 0px 0px 100px;
        width: 72%;
    }

    .categoriesWrap {
        padding: 0 100px 0px 0px;
        width: 28%;
    }

    .list_item {
        width: 290px;
        padding-bottom: 15px;
        font-size: 18px;
        margin-top: 20px;
    }
}