.category_container {
 display: flex;
 flex-wrap: wrap;
 justify-content: center;
}

.category_section h2{
  font-size: 24px;
line-height: 33px;
font-weight: 700;
text-align: center;
margin-bottom: 25px;
}

.category_section span{
  color: #E56D27;
}

/* @media screen and (min-width: 768px) { 
 
} */

@media screen and (min-width: 1024px) { 
.category_section h2{
 font-size: 48px;
line-height: 67px;
margin-bottom: 30px;
}

.category_container {
  max-width: 1200px;
  margin: 0 auto;
}
}

