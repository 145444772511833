.brands {
 width: 95%;
 margin: 0 auto;
 text-align: center;
}

.brands h2 {
 font-size: 24px;
 line-height: 30px;
 margin-bottom: 20px;
}

.brands span {
 color: #E56D27;
}

.brands p {
 font-size: 14px;
line-height: 25px;
margin-bottom: 20px;
}


.brands_container {
 display: flex;
 flex-wrap: wrap;
 justify-content: space-evenly;
}

.smart_heart_img {
  width: 80.41px;
  height: 80px;
}

.me_o_img {
  width: 180.44px;
  height: 80px;
}

.happy_cat_img {
  width: 148.75px;
  height: 120px;
}

@media screen and (min-width: 425px) {
  .smart_heart_img {
    width: 101.41px;
    height: 100px;
  }

  .me_o_img {
    width: 200.44px;
    height: 100px;
  }

  .happy_cat_img {
    width: 168.75px;
    height: 150px;
  }
}
@media screen and (min-width: 1024px) { 
 .smart_heart_img {
  width: 202.81px;
height: 200px;
 }
 
 .me_o_img {
  width: 400.88px;
height: 200px;
 }
 
 .happy_cat_img {
  width: 225px;
height: 200px
 }

 .brands h2 {
  font-size: 48px;
line-height: 30px;
padding-bottom: 20px;
 }

 .brands p {
 font-size: 24px;
 line-height: 30px;
 padding-bottom: 20px;
 }

 /* .brands_child {
  max-width: 1200px;
  margin: 0 auto;
  border: 1px solid;
 } */

 }