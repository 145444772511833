.cardWrap {
    width: 85%;
    max-width: 380px;
    margin: auto;
    background: #F2F3F5;
    border-radius: 150px 140px 62px 62px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.cardWrap img {
    width: 100%;
    object-fit: contain;
}

.cardContentWrap {
    display: flex;
    flex-direction: column;
    height: 360px;
    justify-content: space-evenly;
    padding: 0 0 30px;
    margin: auto;
}

.cardContentWrap svg {
    font-size: 34px;
    color: #00c97a;
}

.cardAddress {
    display: flex;
    align-items: flex-start;
}

.cardContentWrap img {
    width: 25px;
    height: auto;
}

.cardHeader{
    color: #e56d27;
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 5px 15px;
}

.address {
    font-size: 14px;
    color: #57585A;
    padding: 0 0 0 15px;
}

.contactMail {
    display: flex;
    align-items: flex-start;
}

.contactMailWrap {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.contactMailWrap a:first-child {
    padding-bottom: 5px;
}

.contactMailWrap a {
    text-decoration: none;
    font-size: 14px;
    /* padding-left: 15px; */
    color: #57585A;
}

.contactMailWrap p {
    font-size: 14px;
    color: #57585A;
}


@media screen and (min-width:768px){
    .cardWrap {
        width: 100%;
        max-width: 450px;
        margin-top: 0;
    }
}

@media screen and (min-width:1024px) {
    

    .cardContentWrap {
        display: flex;
        flex-direction: column;
        height: 360px;
        justify-content: space-evenly;
        padding: 0 0 30px;
        margin: auto;
        width: 70%;
    }
}