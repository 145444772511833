.landing {
 max-width: 1440px;
 margin: 0 auto;
 position: relative;
}

.header {
 position: sticky;
 left: 0;
 right: 0;
 top: 0;
 z-index: 9999;
}

.categories {
 background: url('../../assests/images/Category_background_mobile.png') center / contain no-repeat;
 margin-bottom: 30px;
}

.shop {
 position: relative;
 overflow: hidden;
 margin-bottom: 30px;

}

.shop > h2 {
 font-size: 24px;
line-height: 33px;
text-align: center;
margin-bottom: 10px;
}

.shop > h2 > span{
 color: #E56D27;
}

.bone_biscuit_img {
 width: 200px;
height: 230px;
}

.img_container {
 position: absolute;
 top: 40%;
 right: -30px;
}

.comments {
 margin-top: 80px;
/* padding-bottom: 40px; */
}

@media screen and (min-width: 768px) { 
 .categories {
 background: url('../../assests/images/Category_background.png');
 background-repeat: no-repeat;
 background-size: 97% 100%;
}

.img_container {
 top: 47%;
}
}

@media screen and (min-width: 1024px) { 
 .shop > h2 {
  font-size: 48px;
line-height: 67px;
margin-bottom: 20px;
 }

 .img_container {
  top: 50%;
  right: 0;
 }

 .brands_main {
  position: relative;
  height: 380px;
 }

 .brands_child {
  position: relative;
  top: -50px;
  max-width: 1200px;
  margin: 0 auto;
 }

}