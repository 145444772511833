.backPageWrap {
    padding-left: 20px;
    margin-bottom: 20px;
}

.backPageWrap a {
    text-decoration: none;
    color: #e56d27;
}

.linkWrap {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.linkWrap img {
    width: 20px;
}

.linkWrap span {
    padding-left: 8px;
    font-size: 14px;
}

@media screen and (min-width:425px) {
    .backPageWrap {
        padding-left: 30px;
    }
}

@media screen and (min-width:768px) {
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .backPageWrap {
        padding-left: 40px;
        margin-bottom: 0px;
    }

    .linkWrap img {
        width: 40px;
    }

    .linkWrap span {
        padding-left: 16px;
        font-size: 18px;
    }
}

@media screen and (min-width:1024px) {
    .backPageWrap {
        padding-left: 80px;
        margin-bottom: 0px;
    }
}