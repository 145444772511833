.container {
    position: relative;
}

.greyImgWrap {
    width: 95%;
    position: absolute;
    z-index: -1;
}

.greyImgWrap img {
    width: 100%;
}


.contentWrap{
    padding: 20px 20px 15px;
}


.contentHead{
    font-size: 24px;
    font-weight: 700;
}

.contentHead span{
    color: #e56d27;
}

.contentDesc{
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    padding: 20px 0;
}

.yellowImgWrap {
    width: 95%;
    z-index: -1;
    position: absolute;
    bottom: -90px;
    right: 0;
}

.yellowImgWrap img {
    width: 100%;
}


@media screen and (min-width:425px){
    .contentWrap {
        padding: 20px 30px 15px;
    }

    .yellowImgWrap {
        width: 95%;
        bottom: -130px;
    }
}


@media screen and (min-width:550px) {
    .yellowImgWrap {
        width: 95%;
        bottom: -170px;
    }
}

@media screen and (min-width:768px) {
    .contentWrap {
        padding: 30px 40px 25px;
    }

    .greyImgWrap {
        width: 70%;
    }


     .yellowImgWrap {
         width: 70%;
         bottom: -140px;
     }

    .contentHead {
        font-size: 48px;
    }

    .contentDesc {
        font-size: 24px;
        line-height: 40px;
        padding: 30px 0;
    }
}

@media screen and (min-width:1024px){
    .contentWrap {
        padding: 35px 80px 25px;
    }

    .greyImgWrap {
        width: 60%;
    }


    .yellowImgWrap {
        width: 60%;
        bottom: -180px;
    }
}



@media screen and (min-width:1200px){
    .yellowImgWrap {
        bottom: -260px;
    }
}