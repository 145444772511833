.container {
    padding: 15px 0;
}

.relateHeader {
    font-size: 18px;
    font-weight: 700;
    padding-left: 20px;
}

.relatedWrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.relatedWrap a{
    text-decoration: none;
    color: inherit;
}

.noProducts {
    font-size: 16px;
    color: #E56D27;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    font-weight: bold;
    text-transform: capitalize;
}

@media screen and (min-width:768px){

    .container {
        padding: 20px 0;
    }

    .relateHeader {
        font-size: 36px;
        padding-left: 40px;
    }

    .relatedWrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
    }

    .noProducts {
        font-size: 24px;
        height: 40vh;
    }
}

@media screen and (min-width:1024px){
    .container {
        padding: 30px 0 20px;
    }
    .relateHeader {
        padding-left: 80px;
    }
}