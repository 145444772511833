.container {
    text-align: end;
    padding-right: 20px;
}

.inputWrap {
    width: 100%;
    position: relative;
}

.inputWrap input {
    width: 85%;
    border: 2.2125px solid #E56D27;
    border-radius: 15.4875px 0px 15.4875px 15.4875px;
    color: #000000;
    padding: 5px 16px;
    outline: none;
}

.inputWrap input::placeholder {
    color: #000000;
    font-size: 12px;
}

.searchBtnWrap {
    position: absolute;
    right: 0;
    top: 0;
}

.searchBtnWrap button {
    background: #E56D27;
    border: 2.2125px solid #E56D27;
    border-radius: 15.4875px 0px 15.4875px 15.4875px;
    font-size: 12px;
    color: #ffffff;
    padding: 6px 16px;
    cursor: pointer;
}

@media screen and (min-width:425px) {
    .container {
        padding-right: 30px;
    }

    .inputWrap input {
        padding: 7px 18px;
    }

    .searchBtnWrap button {
        padding: 8px 18px;
    }

}

@media screen and (min-width:768px){
    .container {
        text-align:initial;
        padding-right: 40px;
        width: 62%;
    }

    .inputWrap {
        width: 100%;
        position: relative;
        
    }

    .inputWrap input {
        width: 100%;
        padding: 10px 24px;
        border: 5px solid #E56D27;
        border-radius: 35px 0px 35px 35px;
        font-size: 18px;
    }

    .inputWrap input::placeholder {
        color: #000000;
        font-size: 18px;
    }

    .searchBtnWrap button {
        font-size: 18px;
        padding: 10px 24px;
        border: 5px solid #E56D27;
        border-radius: 35px 0px 35px 35px;
    }
}

@media screen and (min-width:1024px){
    .container {
        padding-right: 80px;
        width: 60%;
    }

     .searchBtnWrap button {
         padding: 10px 40px;
     }
}