.container {
    padding: 0px 0px 0px;
}

.container h2 {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 15px;
}

.container h2 span {
    color: #E56D27;
}

.card {
 display: flex;
 justify-content: space-between;
 padding: 20px 0;
}

.card:nth-child(odd) {
 flex-direction: row-reverse;
}

.image {
 width: 150px;
 height: 100px;
}

.detail {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 padding: 5px 0 5px 10px;
 }

.detail p {
 font-size: 12px;
line-height: 20px;
font-weight: bold;
}

.desc {
 color: #E56D27;
 width: 127px;
 }

 @media screen and (min-width:375px) {
     .detail p {
         font-size: 14px;
     }

     .image {
         width: 170px;
         height: 125px;
     }

     .desc {
         width: 148px;
     }

     .detail {
         padding: 8px 0 8px 0px;
     }
 }


 @media screen and (min-width:425px) {
     .container {
         padding: 0px 0px 0px;
     }

     .container h2 {
         text-align: center;
     }

     .image {
         width: 200px;
         height: 150px;
     }

     .desc {
         width: 148px;
     }

     .card {
         margin: 0 auto;
         max-width: 400px;
     }
 }


 @media screen and (min-width:768px) {
     .card {
         margin: 0;
         max-width: initial;
     }

     .container h2 {
         text-align: start;
         padding-bottom: 25px;
         font-size: 38px;
     }

     .image {
         width: 300px;
         height: 200px;
     }



     .card {
         margin: 0;
         max-width: initial;
         padding: 30px 30px 30px 0;

     }

     .detail {
         padding: 15px 0 15px 15px;
     }

     .detail p {
         font-size: 16px;
     }

     .desc {
         width: initial;
         max-width: 220px;
         font-size: 20px !important;
         line-height: 33.45px !important;
     }
 }



 @media screen and (min-width:1024px) {
     .container h2 {
         font-size: 48px;
     }

     .image {
         width: 400px;
         height: 300px;
         }


.card {
    margin: 0;
    max-width: initial;
    padding: 30px 30px 30px 0;

}

.detail {
    padding: 20px 0 20px 20px;
}

.detail p {
    font-size: 18px;
}

.desc {
    width: initial;
    max-width: 265px;
    font-size: 24px !important;
    line-height: 33.45px !important;
}
}


@media screen and (min-width:1300px) {
    .card {
        margin: 0;
        max-width: 800px;
        padding: 30px 0px 30px 0;

    }

    .detail {
        padding: 25px 0 25px 0px;
    }
}