.container {
    padding: 0 20px;
}

.quouteMob {
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
    padding: 5px 0;
}

.quouteDesk{
    display: none;
}

.quouteMob span {
    color: #e56d27;
}

.ourMissonContentWrap {
    text-align: start;
    padding: 20px 0;
}

.ourMissonContentWrap h2 {
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: bold;
}

.ourMissonContentWrap span {
    color: #e56d27;
}

.ourMissonContent {
    font-size: 14px;
    line-height: 25px;
    font-weight: normal;
}

.ourMissonContent span{
    color: #e56d27;
}


.missionCardContainer{
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}





@media screen and (min-width:386px) {
    .quouteMob {
        font-size: 24px;
        line-height: 45px;
    }
}


@media screen and (min-width:425px){
    .container {
        padding: 0 30px;
    }


    .missionCardContainer {
        padding: 10px 30px;
    }

}


@media screen and (min-width:768px) {
    .container {
        padding: 0 40px;
    }

    .quouteMob {
        display: none;
    }

    .quouteDesk {
        display: block;
        font-size: 36px;
        font-weight: bold;
        line-height: 45px;
        text-align: center;
        padding: 15px 0;
    }

    .ourMissonContentWrap {
        text-align: start;
        padding: 20px 0 30px;
    }

    .quouteDesk span {
        color: #e56d27;
    }

    .ourMissonContentWrap h2 {
        font-size: 48px;
        margin-bottom: 15px;
    }

    .ourMissonContent {
        font-size: 24px;
        line-height: 40px;
    }

    .missionCardContainer {
        padding: 10px 40px 10px 10px;
    }
}


@media screen and (min-width:1024px){
     .container {
         padding: 0 80px;
     }

     .quouteDesk {
         padding: 20px 0;
     }

     .missionCardContainer {
         padding: 10px 80px 10px 40px;
     }
}

@media screen and (min-width:1300px) {
    .container {
        padding: 0 100px;
    }

    .quouteDesk {
        padding: 25px 0;
    }

     .missionCardContainer {
         padding: 10px 100px 10px 50px;
     }
    
}
