.container {
    padding: 35px 0 20px 30px;
}


.bannerContent {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    width: 190px;
}

.bannerContent span {
    color: #e56d27;
}


.ImgWrap {
    width: 100%;
    max-width: 120px;
    position: relative;
    padding-top: 8px;
}

.ImgWrap img {
    width: 100%;
}

.imgContent {
    position: absolute;
    top: 28px;
    right: 16px;
    transform: rotate(-11deg);
}

.imgContent span {
    font-size: 12px;
    color: #ffffff;
}



@media screen and (min-width:425px) {
    .container {
        padding: 50px 0 30px 40px;
    }

    .ImgWrap {
        padding-top: 18px;
    }

    .imgContent {
        top: 38px;
    }
}



@media screen and (min-width:768px) {


    .container {
        padding: 0 0 0 40px;
        display: flex;
        align-items: center;
        height: 600px;
    }

    .contentWrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 350px;

    }

    .bannerContent {
        font-size: 48px;
        line-height: 67px;
        width: 545px;
    }


    .ImgWrap {
        max-width: 200px;
        padding-top: 0px;
    }

    .imgContent {
        top: 40px;
        right: 24px;
        transform: rotate(-11deg);
    }

    .imgContent span {
        font-size: 20px;
    }
}



@media screen and (min-width:1024px) {


    .container {
        padding: 0 0 0 60px;
        height: 800px;
    }

    .contentWrap {
        height: 400px;
    }


    .ImgWrap {
        max-width: 230px;
        padding-top: 0px;
    }

    .imgContent {
        top: 46px;
        right: 24px;
        transform: rotate(-11deg);
    }

    .imgContent span {
        font-size: 24px;
    }
}

@media screen and (min-width:1200px) {
    .container {
        padding: 0px 0 0px 120px;
        height: 820px;
    }



    .contentWrap {
        height: 450px;
    }
}