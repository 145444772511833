.container {
    margin-top: -30px;
    position: relative;
}

.contentWrap {
    padding: 0 20px;
}

.contentWrap h2 {
    font-size: 24px;
    font-weight: bold;
}

.contentWrap span {
    color: #e56d27;
}

.contentWrap p {
    font-size: 14px;
    line-height: 25px;
    font-weight: normal;
    padding-top: 15px;
}

.accordionBg {
    padding: 20px 20px;
}

.imgWrap {
    position: absolute;
    width: 90%;
    bottom: -40px;
    right: 0;
    z-index: -1;
}

.imgWrap img {
    width: 100%;
}



@media screen and (min-width:425px) {

    .container {
        margin-top: -35px;
    }

    .contentWrap {
        padding: 0 30px;
    }

    .accordionBg {
        padding: 20px 30px;
    }

    .imgWrap {
        bottom: -80px;
    }

}



@media screen and (min-width:550px) {
    .imgWrap {
        width: 70%;
        bottom: -80px;
    }
}


@media screen and (min-width:768px) {
    .container {
        margin-top: -75px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .contentWrap {
        padding: 0 0px 0 40px;
        width: 46%;
    }

    .contentWrap h2 {
        font-size: 48px;
    }

    .contentWrap p {
        font-size: 20px;
        line-height: 35px;
        padding-top: 15px;
    }

    .accordionBg {
        padding: 0px 40px 0 10px;
        width: 52%;
    }

    .imgWrap {
        width: 60%;
        bottom: 0px;
        top: 50px;
    }
}


@media screen and (min-width:1024px) {
    .container {
        margin-top: -110px;
        display: flex;

    }

    .contentWrap {
        padding: 0 30px 0 80px;
        max-width: 700px;
        width: 50%;
    }

    .contentWrap h2 {
        font-size: 48px;
    }

    .contentWrap p {
        font-size: 24px;
        line-height: 35px;
        padding-top: 15px;
    }

    .accordionBg {
        padding: 0px 80px 0 30px;
        width: 50%;
    }

    .imgWrap {
        width: 55%;
        bottom: 0px;
        top: -40px;
    }
}

@media screen and (min-width:1300px) {
    .contentWrap {
        padding: 0 30px 0 100px;
    }

    .accordionBg {
        padding: 0px 100px 0 30px;
    }

    .imgWrap {
        width: 55%;
        bottom: 0px;
        top: -80px;
    }
}