.footer {
 width: 95%;
 margin: 0 auto;
padding: 10px;

}

.social_media {
 width: 182px;
 display: flex;
 justify-content: space-between;
 margin-top: 5px;
 margin-bottom: 5px;
}

.logo {
 width: 182px;
height: 60px;
}


.icon {
 height: 35px;
 width: 35px;
 border-radius: 50%;
 display: flex;
 justify-content: center;
 align-items: center;
 background-color: #E56D27;
 cursor: pointer;
}

.footer_nav {
 display: flex;
 margin: 20px auto;
}

.nav_items {
 width: 50%;
 color: #57585A;
}

.footer_nav li {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  cursor: pointer;
  list-style: none;
}

.newsletter_container {
 display: flex;
 flex-direction: column;
 justify-content: center;
 text-align: center;
}

.newsletter_container p {
 font-size: 24px;
line-height: 35px;
color: #E56D27;
margin-bottom: 10px;
}

.input {
 width: 100%;
height: 40px;
border: 1px solid #000000;
border-radius: 50px;
font-size: 14px;
line-height: 35px;
font-weight: 400;
color: #57585A;
padding-left: 30px;
margin-bottom: 10px;
}

.btn {
 background: #E56D27;
border-radius: 40px 0px 60px 40px;
outline: none;
border: none;
color: white;
height: 40px;
font-size: 14px;
line-height: 35px; 
font-weight: 400;
cursor: pointer;
}

.copyright {
 text-align: center;
 color: #E56D27;
 margin-top: 20px;
 font-size: 14px;
line-height: 35px;
}

.copyright span {
 display: inline-block;
 vertical-align: top;
 margin-right: 5px;
 padding-top: 4px;
}

@media screen and (min-width: 768px) {
 .footer_main {
  display: flex;
 }
 .footer_nav {
  width: 40%;
 margin: 0px auto;
 align-items: center;
 justify-content: space-around;
}

}

@media screen and (min-width: 1024px) {
 .logo {
width: 225px;
height: 75px;
}

.icon {
 height: 50px;
 width: 50px;
}

.footer_nav {
  width: 40%;
}

.footer_nav li {
 font-size: 18px;
 line-height: 35px;
}
.nav_items {
 width: 45%;
}


.social_media {
 width: 250px;
}

.newsletter_container {
 width: 30%;
}
}