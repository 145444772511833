.container {
    padding: 35px 20px 15px;
    position: relative;
}

.formHeader{
    font-size: 14px;
    font-weight: bold;
}

.formWrap {
    padding-top: 15px;
}

.inputWrap {
    display: flex;
    flex-direction: column;
    padding-top: 14px;  
}

.inputWrap p {
    color: red;
    padding: 6px;
    font-size: 12px;
    /* border: 1px solid black; */
}

.inputWrap div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  /* border: 1px solid red; */
}

.inputWrap textarea {
    resize: none;
}

.inputWrap label {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 2px;
}

.inputWrap span {
    color: #e56d27;
}

.inputWrap textarea {
    width: 100%;
    border-radius: 7.76583px;
    padding: 6px 10px;
    outline: none;
    border: 0.388292px solid #000000;
}


.inputWrap input {
    width: 100%;
    padding: 6px 10px;
    border-radius: 7.76583px;
    outline: none;
    border: 0.388292px solid #000000;
}


.checkboxWrap {
    display: flex;
    align-items: center;
    padding: 15px 0 18px;
}

.checkboxWrap input {
    appearance: none;
    background-color: #ffffff;
    border: 1px solid #000000;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
}


.checkboxWrap input:checked:active,
.checkboxWrap input:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}


.checkboxWrap input:checked {
    background: #e56d27;
    border: 1px solid #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
        inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

.checkboxWrap input:checked:after {
    content: '\2714';
    font-size: 12px;
    position: absolute;
    top: -1px;
    left: 3px;
    color: #ffffff;
}


.checkboxWrap span {
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
}


.imgWrap{
    position: absolute;
    top: 110px;
    right: 0;
    width: 85%;
    z-index: -1;
}

.imgWrap img{
    width: 100%;
}

.label {
    display: flex;
}


@media screen and (min-width:768px) {


    .container {
        padding: 50px 0px 40px 40px;
    }

    .formHeader {
        font-size: 36px;
    }

    .formWrap {
        padding-top: 18px;
    }

    .inputWrap {
        padding-top: 18px;
        width: 85%;
    }

    
    .inputWrap p {
        font-size: 16px;
        /* border: 1px solid black; */
    }   

    .inputWrap label {
        font-size: 24px;
        padding-bottom: 4px;
    }

    .inputWrap textarea {
        border-radius: 20px;
        font-size: 20px;
        padding: 12px 16px;
        border: 1px solid #000000;
    }


    .inputWrap input {
        padding: 12px 16px;
        font-size: 20px;
        border-radius: 20px;
        border: 1px solid #000000;
    }

    .checkboxWrap span {
        font-size: 24px;
        margin-left: 15px;
        width: 80%;
    }

    .checkboxWrap input {
        padding: 11px;
        border-radius: 3px;
    }

    .checkboxWrap input:checked:after {
        font-size: 16px;
        top: -1px;
        left: 4px;
    }

    .checkboxWrap {
        padding: 18px 0 22px;
    }

    .imgWrap {
        top: 110px;
        width: 70%;
    }

    .label {
        display: flex;
    }
}


@media screen and (min-width:1024px) {
    .container {
        padding: 25px 0px 45px 80px;
    }

    .inputWrap {
        padding-top: 18px;
        width: 75%
    }

    .checkboxWrap span {
        font-size: 24px;
        margin-left: 15px;
        width: 70%;
    }

    .imgWrap {
        top: 50px;
        width: 58%;
    }

    .label {
        display: flex;
    }

}