.container {
    padding: 10px 0;
}

.anwserClose {
    border: 3.21725px solid #E56D27;
    border-radius: 22.5207px 0px 45.0794px 45.0794px;

}

.answerOpen {
    border: 3.21725px solid #E56D27;
    border-radius: 22.5207px 0px 22.5207px 22.5207px;
}



.questionWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 18px;
}


.questionContent {
    font-size: 12px;
    font-weight: normal;
}

.btnWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnWrap button {
    border: none;
    outline: none;
    background: none;
}

.imgWrap {
    display: flex;
    align-items: center;
}

.imgWrap img {
    width: 20px;
}

.answerWrap {
    background: #E56D27;
    border-radius: 0px 0px 40.0794px 40.0794px;
}


.answerWrap p {
    font-size: 12px;
    font-weight: normal;
    color: #ffffff;
    padding: 15px 20px 15px;
    line-height: 19px;
}




@media screen and (min-width:375px) {
    .questionContent {
        font-size: 14px;
    }
}


@media screen and (min-width:425px) {
    .answerWrap p {
        padding: 15px 20px 15px;
    }

    .answerWrap {
        background: #E56D27;
        border-radius: 0px 0px 40.0794px 40.0794px;
        margin-right: -1px;
    }
}


@media screen and (min-width:768px) {

    .container {
        width: 100%;
        padding: 15px 0;
    }

    .questionWrap {
        padding: 10px 18px;
    }

    .questionContent {
        font-size: 16px;
    }

    .questionWrap {
        width: 100%;
    }

    .imgWrap img {
        width: 24px;
    }

    .anwserClose {
        border: 4px solid #E56D27;
        border-radius: 35px 0px 70px 70px;
    }

    .answerOpen {
        border: 4px solid #E56D27;
        border-radius: 35px 0px 35px 35px;

    }

    .answerWrap {
        border-radius: 0px 0px 63px 63px;
    }

    .answerWrap p {
        font-size: 16px;
        padding: 18px 20px 24px;
        line-height: 22px;

    }

}


@media screen and (min-width:1024px) {
    
    .questionContent {
        font-size: 18px;
    }

     .questionWrap {
         padding: 12px 22px;
     }

    .questionWrap {
        width: 100%;
    }

    .imgWrap img {
        width: 28px;
    }

    .anwserClose {
        border: 5px solid #E56D27;
        border-radius: 35px 0px 70px 70px;
    }

    .answerOpen {
        border: 5px solid #E56D27;
        border-radius: 35px 0px 35px 35px;
    }

    .answerWrap {
        margin-bottom: -1px;
    }

     .answerWrap p {
         font-size: 18px;
         padding: 18px 22px 24px;
         line-height: 30px;
     }
}

@media screen and (min-width:1300px){

    .container {
        padding: 18px 0;
    }
    .questionWrap {
        padding: 12px 30px 12px 40px;
    }

    .answerWrap p {
        font-size: 18px;
        padding: 18px 22px 26px;
        line-height: 30px;
    }
}