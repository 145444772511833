.container {
    text-align: center;
    margin: 25px 0;
}

.container p {
    font-size: 18px;
    font-weight: 700;
}

.numberWrap {
    font-weight: 700;
    color: #e56d27;
    margin-top: 8px;
}

.numberWrap span {
    margin: 0 5px;
    font-size: 18px;

}

.numberWrap a {
    text-decoration: none;
    color: #e56d27;
    font-size: 18px;
}

@media screen and (min-width:425px) {
    .container {
        margin: 30px 0;
    }
}

@media screen and (min-width:768px) {

    .container p {
        font-size: 38px;
    }

    .numberWrap span {
        margin: 0 10px;
        font-size: 38px;

    }

    .numberWrap a {
        font-size: 38px;
    }
}

@media screen and (min-width:1024px) {

    .container p {
        font-size: 48px;
    }

    .numberWrap span {
        margin: 0 15px;
        font-size: 48px;

    }

    .numberWrap {
        font-weight: 700;
        color: #e56d27;
        margin-top: 10px;
    }

    .numberWrap a {
        font-size: 48px;
    }
}