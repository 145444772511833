.card {
 width: 230px;
 display: flex;
 flex-direction: column;
 align-items: center;
 margin-bottom: 20px;
}

.profile_image {
 width: 190px;
 height: 190px;
}

.card h3 {
 font-size: 24px;
line-height: 33px;
font-weight: 700;
text-transform: capitalize;
padding-bottom: 15px;
}

.card p {
 font-size: 18px;
line-height: 25px;
font-weight: 400;
margin: 5px;
}

@media screen and (min-width: 425px) {
    .profile_image {
        width: 221.45px;
        height: 232.16px;
    }
}

@media screen and (min-width: 1024px) {
    .card {
        width: 310px;
        margin-bottom: 30px;
    }

    .card h3 {
        font-size: 36px;
        line-height: 50px;
    }

    .card p {
        font-size: 24px;
        line-height: 33px;
        margin: 10px;
    }

    .profile_image {
        width: 280px;
        height: 280px;
    }
}