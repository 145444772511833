.container {
    width: 100%;
    padding: 25px 0 30px 25px;
}


.content {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    max-width: 180px;
}

.content span {
    color: #e56d27;
}

.contentDesc {
    display: none;
}

.ImgWrap {
    width: 100%;
    max-width: 120px;
    position: relative;
    padding-top: 8px;
}

.ImgWrap img {
    width: 100%;
}

.imgContent {
    position: absolute;
    top: 29px;
    right: 32px;
    transform: rotate(-11deg);
}

.imgContent span {
    font-size: 14px;
    color: #ffffff;
}


@media screen and (min-width:425px) {
    .container {
        padding: 50px 0 30px 35px;
    }
}


@media screen and (min-width:768px) {
    .container {
        padding: 0px 0 0px 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 650px;
    }

    .content {
        font-size: 48px;
        line-height: 67px;
        max-width: initial;
    }


    .contentDesc {
        display: block;
        max-width: 470px;
        font-size: 24px;
        line-height: 33px;
        font-weight: normal;
        padding: 15px 0;
    }

    .ImgWrap {
        max-width: 200px;
        padding-top: 0px;
    }

    .imgContent {
        top: 40px;
        right: 54px;
        transform: rotate(-11deg);
    }

    .imgContent span {
        font-size: 24px;
    }
}

@media screen and (min-width:1024px) {
    .container {
        padding: 0px 0 0px 60px;
        height: 800px;
    }

    .content {
        word-spacing: 10px;
    }


    .contentDesc {
        padding: 35px 0 20px 0;
    }

    .ImgWrap {
        max-width: 230px;
        padding-top: 0px;
    }

    .imgContent {
        top: 48px;
        right: 72px;
        transform: rotate(-11deg);
    }

    .imgContent span {
        font-size: 24px;
    }
}

@media screen and (min-width:1200px) {
    .container {
        padding: 0px 0 0px 100px;
        height: 800px;
    }
}