/* .container{
    max-width: 1440px;
    margin: 0 auto;
} */


.bannerBg {
    background: url('../../assests/images/aboutUs-mobile.png');
    height: 300px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}




@media screen and (min-width:425px) {
    .bannerBg {
        height: 350px;
    }
}

@media screen and (min-width:768px) {
    .bannerBg {
        background: url('../../assests/images/aboutUs-desktop.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 720px;
        margin-top: -100px;
    }

}


@media screen and (min-width:1024px) {
    .bannerBg {
        height: 940px;
    }

    
}
