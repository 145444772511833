.container{
    max-width: 1400px;
    margin: 0 auto;
}

.heroBg {
    width: 100%;
    height: 150px;
    background: url('../../assests/images/productDesc_Bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}


.productContainer {
    padding: 20px 0px 40px;
}


.category_section {
    padding-top: 30px;
    width: 80%;
    margin: 0 auto;
}

.heading {
    font-size: 18px;
    line-height: 25px;
    color: #E56D27;
    padding-bottom: 10px;
}

.underline {
    width: 50px;
    height: 4px;
    background: #E56D27;
}

.list_item {
    width: 140px;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 5px;
    text-transform: capitalize;
    cursor: pointer;
    margin-top: 10px;
    font-size: 18px;
    list-style: none;
}



@media screen and (min-width:425px) {
    .heroBg {
        height: 180px;
    }
}

@media screen and (min-width:768px) {
    .heroBg {
        height: 500px;
        margin-top: -100px;
    }

    .productContainer {
        padding: 40px 0px 40px;
        display: flex;
        width: 100%;
    }

    .productReviewWrap {
        width: 80%;
        margin: 0 auto;
    }

    .categoryWrap {
        width: 30%;
    }

    .category_section {
        padding-top: 40px;
    }
}


@media screen and (min-width:900px) {
    .productReviewWrap {
        width: 80%;
        margin: 0 auto;
    }

    .categoryWrap {
        width: 25%;
    }
}


@media screen and (min-width:1024px) {
    .productContainer {
        padding: 50px 0px 40px;
    }

    .productReviewWrap {
        width: 80%;
        margin: 0 auto;
    }

    .categoryWrap {
        width: 20%;
        padding-top: 20px;
    }

    .category_section {
        padding-top: 60px;
    }
}