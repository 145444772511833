.container {
    padding: 20px 0;
}


.imgWrap img {
    width: 95%;
    height: 100%;
}


@media screen and (min-width:768px) {
    .container {
        display: flex;
        align-items: center;
        padding-right: 29.2px;
    }

    .imgWrap {
        width: 100%;
        height: 550px;
    }

    .imgWrap img {
        width: 100%;
    }
}

@media screen and (min-width:1024px){
    .container {
        padding-right: 35px;
    }
}

@media screen and (min-width:1200px) {
    .container {
        padding-right: 48px;
    }
}