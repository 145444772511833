.imgWrap {
    width: 100%;
    margin-top: -35px;
}

.imgWrap img {
    width: 90%;

}


.contentWrap {
    padding: 15px 20px 40px;
    text-align: start;
}

.contentHeader {
    font-size: 24px;
    line-height: 40px;
    font-weight: bold;
}

.contentHeader span {
    color: #e56d27;
}

.contentDesc {
    font-size: 14px;
    line-height: 25px;
    font-weight: normal;
    padding-top: 10px;
}


@media screen and (min-width:425px) {
    .contentWrap {
        padding: 15px 30px 40px;
    }
}


@media screen and (min-width:550px) {
    .imgWrap img {
        width: 90%;
        height: 420px;
    }
}


@media screen and (min-width:768px) {

    .container{
        display: flex;
        align-items: center;
        padding: 30px 0 40px;
    }

    .contentWrap {
        padding: 0px 40px 0px 10px;
        width: 50%;
    }

    .imgWrap {
        width: 50%;
        margin-top: -35px;
    }

    .imgWrap img {
        width: 100%;
        height: initial;
    }

    .contentHeader {
        font-size: 38px;
    }

    .contentDesc {
        font-size: 20px;
        line-height: 30px;
        padding-top: 20px;
    }

}


@media screen and (min-width:1024px){
    .contentWrap {
        padding: 0px 80px 0px 15px;
    }

    .imgWrap {
        width: 50%;
        margin-top: -35px;
    }

    .imgWrap img {
        width: 100%;
        height: initial;
    }

    .contentHeader {
        font-size: 48px;
    }

    .contentDesc {
        font-size: 24px;
        line-height: 40px;
        padding-top: 30px;
    }
}



@media screen and (min-width:1300px){
    .contentWrap {
        padding: 0px 100px 0px 15px;
    }

    .imgWrap {
        width: 50%;
        margin-top: -85px;
    }

    .container {
        padding: 50px 0 60px;
    }
}