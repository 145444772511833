.btn {
 width: 118.65px;
height: 36.85px;
background: #E56D27;
outline: none;
cursor: pointer;
border: none;
color: white;
font-size: 14px;
font-weight: 400;
line-height: 26px;
border-radius: 0px 22.1366px 22.1366px 22.1366px;
}

@media screen and (min-width: 768px) { 
 .btn {
  width: 160px;
height: 50px;
font-size: 18px;
line-height: 35px;
border-radius: 0px 30px 30px 30px;
 }
}