.heroContentWrap{
    width: 70%;
    height: 60px;
}

.heroContent{
    width: 100%;
    font-weight: 700;
    color: #e56d27;
    font-size: 24px;
}

.heroContent span{
    color: black;
}
@media screen and (min-width:425px) {
    .heroContentWrap {
        height: 50px;
    }
}


@media screen and (min-width:768px) {
    .heroContentWrap {
        width: 85%;
        height: 90px;
    }

    .heroContent {
        font-size: 48px;
    }
}

@media screen and (min-width:1024px) {
    .heroContentWrap {
        width: 86%;
        height: 150px;
    }

    .heroContent {
        font-size: 72px;
    }
}