.women_image {
 width: 95%;
}
.quotes {
 width: 22.37px;
height: 17.38px;
margin-bottom: 15px 0px 15px 0px;
}

.carousel_container {
 width: 95%;
 margin: 0 auto;
}

.carousel {
 padding-bottom: 30px;
 width: 95%;
 margin: 0 auto;
}

.active {
 background: #E56D27;
 margin-right: 5px;
 width: 20px;
 height: 20px;
 border-radius: 50%;
 justify-content: flex-start;
 display: flex;
 cursor: pointer;
}

.inactive {
 background: #000000;
 margin-right: 5px;
 width: 20px;
 height: 20px;
 border-radius: 50%;
 justify-content: flex-start;
 cursor: pointer;

}

.dots {
 width: 90px;
}

.circle {
 width: 20px;
 height: 20px;
 border-radius: 50%;
 display: inline-block;
}

.carousel p {
 font-size: 14px;
line-height: 20px;
}

.comment {
 margin-bottom: 20px;
}

.user_info {
 color: #E56D27;
}

@media screen and (min-width: 768px) { 
 .carousel_conatiner {
  display: flex;
  align-items: center;
 }

 .women_image, .carousel_outside {
  width: 45%;
 }

 .carousel_outside {
  margin-left: 20px;
 }
 .dots {
  width: 90px;
  padding-left: 10px;
 }
 

}

@media screen and (min-width: 1024px) { 
 .carousel p {
  font-size: 24px;
 line-height: 33px;
 }
 .dots {
  width: 100px;
  padding-left: 10px;
 }

 }
