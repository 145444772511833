.testimony_container {
 text-align: center;
}

.testimony_container p {
 font-size: 14px;
line-height: 20px;
}

.testimonial_quotes {
 width: 16px;
 height: 12px;
 display: inline-block;
 margin-bottom: 10px;
}

.testimonial_image {
 width: 75px;
 height: 75px;
 display: inline-block;
 margin-bottom: 10px;
}

.text {
 color: #E56D27;
}

@media screen and (min-width:768px) { 

 .testimony_container p {
  font-size: 24px;
line-height: 33px;
 }

 .testimonial_image {
  width: 200px;
  height: 200px;
 }

 .testimonial_quotes {
   width: 35px;
   height: 26px;
 }

}

@media screen and (min-width:1024px) { 

 .testimony_container p {
  width: 900px;
  margin: 0 auto;
 }

 .testimony {
  padding: 20px;
 }

}