.container {
    width: 100%;
    padding: 50px 0 60px;
}


.aboutBg {
    width: 100%;
    background: url('../../../assests/images/Yellow_Amoeba.png');
    background-position: left;
    background-repeat: no-repeat;
    background-size: 95% 100%;
    height: 280px;
    position: relative;
}

.contentWrap {
    width: 100%;
    padding: 0 20px;
    position: relative;
    top: -30px;
}

.aboutHeader {
    font-size: 24px;
    font-weight: 700;
    text-align: start;
    padding-bottom: 20px;
}

.aboutHeader span {
    color: #E56D27;
}

.aboutDesc {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
}

.readBtnWrap {
    padding: 30px 0 0;
}

.readBtn {
    background: #E56D27;
    text-decoration: none;
    color: #ffffff;
    padding: 4px 16px;
    width: 100%;
    border-radius: 0px 18px 18px 18px;
}

.foodWrap {
    width: 50%;
    position: absolute;
    bottom: -56px;
    right: 0;
}

.foodWrap img {
    width: 100%;
}

.desktopWrap {
    display: none;
}


@media screen and (min-width:425px) {
    .foodWrap {
        width: 40%;
        position: absolute;
        bottom: -25px;
        right: 0;
    }
}

@media screen and (min-width:620px) {

    .foodWrap {
        width: 30%;
        position: absolute;
        bottom: -20px;
        right: 0;
    }

    .aboutDescWrap {
        max-width: 600px;
    }

    .aboutBg {
        height: 240px;
    }

    .contentWrap {
        padding: 0 30px;
    }
}


@media screen and (min-width:768px) {
    .container {
        padding: 0px 0px 40px 0;
    }

    .aboutBg {
        display: none;
    }

    .desktopWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .imgWrap {
        width: 60%;
        position: relative;
    }

    .yellowImg {
        width: 100%;
    }

    .foodImg {
        width: 50%;
        position: absolute;
        right: 140px;
        top: 60px;
    }

    .foodImg img {
        width: 100%;
    }

    .stickImg {
        width: 40%;
        position: absolute;
        bottom: 100px;
        right: 150px;
    }

    .stickImg img {
        width: 100%;
    }

    .desktopContentWrap {
        width: 60%;
        padding: 0px 50px 0 0;
        margin-left: -120px;
        z-index: 10;
    }

    .desktopAboutHeader {
        font-size: 30px;
        font-weight: 700;
        text-align: start;
        padding-bottom: 20px;
    }

    .desktopAboutHeader span {
        color: #E56D27;
    }

    .desktopAboutDesc {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.6;
    }

    .desktopReadBtnWrap {
        padding: 30px 0 0;
    }

    .desktopReadBtn {
        background: #E56D27;
        text-decoration: none;
        color: #ffffff;
        padding: 4px 16px;
        width: 100%;
        font-size: 18px;
        border-radius: 0px 25px 25px 25px;

    }
}


@media screen and (min-width:1024px) {

    .foodImg {
        width: 40%;
        position: absolute;
        right: 190px;
        top: 80px;
    }

    .stickImg {
        width: 40%;
        bottom: 160px;
        right: 190px;
    }

    .desktopContentWrap {
        padding: 0px 70px 0 0;
    }

    .desktopAboutHeader {
        font-size: 38px;
    }

    .desktopAboutDesc {
        font-size: 22px;
    }

    .desktopReadBtnWrap {
        padding: 30px 0 0;
    }

    .desktopReadBtn {
        font-size: 22px;
        padding: 8px 18px;
        border-radius: 0px 30px 30px 30px;
    }
}

@media screen and (min-width:1200px) {
    .imgWrap {
        margin-top: 80px;
    }


    .foodImg {
        width: 40%;
        right: 200px;
        top: 80px;
    }

    .stickImg {
        width: 40%;
        bottom: 180px;
        right: 210px;
    }

    .desktopContentWrap {
        padding: 100px 70px 0 0;
    }

    .desktopAboutHeader {
        font-size: 48px;
    }

    .desktopAboutDesc {
        font-size: 24px;
        width: 680px;
    }

    .desktopReadBtnWrap {
        padding: 35px 0 0;
    }

    .desktopReadBtn {
        font-size: 24px;
        border-radius: 0px 38px 38px 38px;
    }
}


@media screen and (min-width:1350px){
    .foodImg {
        width: 45%;
        right: 250px;
        top: 100px;
    }

    .stickImg {
        width: 40%;
        bottom: 210px;
        right: 270px;
    }

    .desktopContentWrap {
        padding: 130px 0px 0 0;
    }
}