.container {
    max-width: 1440px;
    margin: 0 auto;
}

.heroBg {
    width: 100%;
    height: 150px;
    background: url('../../assests/images/rabbitBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

}


.categoryNameWrap p {
    font-size: 16px;
    color: #E56D27;
    padding-bottom: 10px;
    font-weight: bold;
    text-transform: capitalize;
}


.products {
    padding: 20px 20px;
}

.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.list a {
    text-decoration: none;
    color: inherit;
}

.heading {
    font-size: 18px;
    line-height: 25px;
    color: #E56D27;
    padding-bottom: 10px;
}

.underline {
    width: 50px;
    height: 4px;
    background: #E56D27;
}

.list_item {
    width: 140px;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 5px;
    text-transform: capitalize;
    cursor: pointer;
    margin-top: 10px;
    list-style: none;
}

.list_item_selected {
    width: 140px;
    color: #E56D27;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 5px;
    text-transform: capitalize;
    cursor: pointer;
    margin-top: 10px;
    list-style: none;
}

.category_section {
    padding-top: 30px;
}

.noProducts {
    font-size: 16px;
    color: #E56D27;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    text-transform: capitalize;
    font-weight: bold;
}

@media screen and (min-width:425px) {
    .heroBg {
        height: 180px;
    }
.products {
    padding: 20px 30px;
}
}


@media screen and (min-width:768px) {
    .heroBg {
        height: 500px;
        margin-top: -100px;
    }

     .pagesearchWrap {
         padding: 10px 0px;
     }

    .products {
        padding: 30px 40px;
        display: flex;
        justify-content: space-between;
    }

    .product_list {
        width: 80%;
    }

    .category_section {
        padding-top: 40px;
        }

        .categoriesWrap {
            width: 20%;
        }

        .categoryNameWrap p {
            font-size: 20px;
            padding-bottom: 15px;
        }

        .noProducts {
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 70vh;
            font-weight: bold;
        }
        }

@media screen and (min-width: 1024px) {

    .list {
        justify-content: space-evenly;
        align-items: center;
        padding-right:15px;
    }

    .products {
        display: flex;
        padding: 35px 80px 30px;
    }

    .product_list {
        width: 80%;
    }

    .categoriesWrap {
        width: 20%;
    }

    .category_section {
        padding-top: 50px;
        width: 100%;
    }

     .categoryNameWrap p {
         font-size: 24px;
         padding-bottom: 20px;
         padding-top: 10px;
     }

    }

    @media screen and (min-width:1200px) {
        .product_list {
            width: 82%;
        }

        .categoriesWrap {
            width: 18%;
        }
    }
@media screen and (min-width:1300px) {
    .products {
        padding: 20px 100px;
    }

    .pagesearchWrap {
        padding: 20px 20px;
    }


    .product_list {
        width: 85%;
    }

    .categoriesWrap {
        width: 15%;
    }

    .category_section {
        padding-top: 60px;
    }

}
